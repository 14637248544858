.console-dropdown-text {
  display: inline-block;
  line-height: 1.3em;
  font-weight: normal;
  font-size: 0.9em;
}
.console-dropdown-text-secondary {
  color: #868f95;
}
.awsui .console-dropdown .awsui-button.awsui-button-variant-normal {
  background-color: transparent;
  color: white;
  border: none;
  white-space: pre;
  text-align: right;
}
.awsui .console-dropdown .awsui-button-icon {
  top: -9px;
}
.awsui .console-dropdown .awsui-button-dropdown.awsui-button-dropdown-drop-left {
  margin-top: -3px;
}
